/**
 * @define DataTable
 */

.DataTable {
  width: 100%;
  border-collapse: collapse;

  td {
    padding: var(--gutter-tiny) var(--gutter);

    &:first-child {
      padding-top: var(--gutter-small);
    }

    &:last-child {
      padding-bottom: var(--gutter-small);
    }

    @media (--medium) {
      padding: var(--gutter-small) var(--gutter);
    }
  }
}

.DataTable thead {
  display: none;
  color: var(--colour-grey-dark);
  font-weight: normal !important;
  font-family: var(--font-headings);

  @media (--medium) {
    display: table-row-group;
  }
}

.DataTable tbody td {
  display: block;

  &::before {
    content: attr(data-label);

    @media (--medium) {
      display: none;
    }
  }

  @media (--medium) {
    display: table-cell;
  }
}

.DataTable tr {
  border-bottom: var(--colour-grey-light) 1px solid;
}

.DataTable tbody tr {
  &::nth-child(odd) {
    background-color: var(--colour-table-odd);
  }

  &:nth-child(even) {
    background-color: var(--colour-table-even);
  }

  &:last-child {
    border-bottom: none;
  }
}
