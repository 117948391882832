/**
 * @define ToolHeaderSearch
 */

.ToolHeaderSearch {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--gutter);
  white-space: nowrap;

  a {
    color: var(--colour-grey-light) !important;
    text-decoration: none !important;
    transition: color 0.2s ease;

    &:hover {
      color: white !important;
    }
  }

  &-icon {
    display: inline-block;
    font-size: 26px;
  }

  &-contents {
    position: absolute;
    top: -3px;
    right: 0;
    z-index: 10;
    display: inline-block;
    max-width: 26px;
    padding: 0 var(--gutter-large);
    overflow: hidden;
    white-space: nowrap;
    background-color: var(--colour-brand);
    transition: max-width 0.4s ease;
  }

  &-input {
    width: 250px;
    padding: var(--gutter-small) var(--gutter) 0 var(--gutter);
    border: 0;
    color: white;
    font-size: var(--font-size-input);
    font-family: var(--font-body);
    background-color: transparent;
    outline: none;
    caret-color: var(--colour-main);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @media (--medium) {
      width: 200px;
    }

    @media (--large) {
      width: 350px;
    }

    &::placeholder {
      color: var(--colour-grey-light);
    }
  }

  &-close {
    font-size: 26px;
    opacity: 0;
    transition: opacity 1s ease !important;
  }

  &.is-expanded &-contents {
    max-width: 430px;
  }

  &.is-expanded &-close {
    z-index: 20;
    opacity: 1;
  }
}
