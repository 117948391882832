/**
 * @define ToolHeaderSticky
 */

.ToolHeaderSticky {
  position: relative;
  z-index: 90;
  width: 100%;
  height: 100px;
  margin-top: 100px;

  &-content {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: var(--gutter) var(--gutter-large);
    color: white;
    background-color: var(--colour-brand);

    &.is-animated {
      transition: transform 0.3s ease;
    }

    &.is-fixed {
      position: fixed;
      top: 100px;
      transform: translateY(-101px);

      &.is-shown {
        transform: translateY(0);
      }
    }
  }

  &-button {
    @mixin buttonSizing;

    @mixin buttonInteraction;
    position: relative;
    overflow: hidden;
    color: white;
    font-size: var(--font-size-input);
    text-align: center;
    background-color: var(--colour-main);

    @media (--large) {
      min-width: 265px;
    }
  }

  &-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
