/**
 * @define FilterGroup
 */

.FilterGroup {
  display: inline-block;
  border: var(--colour-grey-light) 1px solid;
  border-radius: var(--border-radius-button);
  overflow: hidden;
  font-size: 0;
  background-color: white;

  &--span {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }
}

.FilterGroup-input {
  display: none;
}

.FilterGroup-label {
  display: inline-block;
  width: 100%;
  padding: var(--button-padding-filter);
  text-align: center;
  cursor: pointer;
}

.FilterGroup-input:checked + .FilterGroup-label {
  color: white;
  background-color: var(--colour-main);
}

.FilterGroup-item {
  position: relative;
  display: inline-block;
  font-size: initial;

  &:not(:last-child) {
    border-right: var(--colour-grey-light) 1px solid;
  }

  .FilterGroup--span & {
    flex: 2 0 auto;
  }
}

.FilterGroup--vertical {
  &.FilterGroup--span {
    flex-direction: column;
  }

  .FilterGroup-item {
    display: block;

    &:not(:last-child) {
      border-right: 0;
      border-bottom: var(--colour-grey-light) 1px solid;
    }
  }
}
