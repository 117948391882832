/**
 * @define StatusPill
 */

.StatusPill {
  width: 90px;
  padding: 7px 5px 4px;
  border: var(--colour-grey-light) 1px dashed;
  border-radius: 15px;
  color: var(--colour-brand);
  font-size: var(--font-size-tiny);
  font-family: var(--font-headings);
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  background-color: white;

  &.is-processing {
    border: unset;
    background-color: var(--colour-grey-lighter);
  }

  &.is-new {
    border: unset;
    color: white;
    background-color: var(--colour-main);
  }

  &.is-active {
    border: unset;
    color: var(--colour-main);
    background-color: var(--colour-grey-lighter);
  }

  &::after {
    position: absolute;
    left: 0;
    width: 100%;
    padding-top: 28px;
    font-family: var(--font-body);
    text-align: center;
    text-transform: none;
  }

  &[data-description]:not([data-description=''])::after {
    content: attr(data-description);
  }
}
