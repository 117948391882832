/**
 * @define LandingPage
 */

.LandingPage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10%;
  background: var(--colour-brand);
  background: linear-gradient(
    -40deg,
    var(--colour-brand) 0%,
    var(--colour-brand) 50%,
    var(--colour-main) 50%,
    var(--colour-main) 100%
  );

  &-logo {
    margin-right: 15%;
    transform: scale(1.5);
  }

  &-content {
    width: 400px;
    min-height: 100px;
    margin: 5vw;
    padding: var(--gutter-xlarge);
    background-color: white;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-header {
    display: flex;
    width: 100%;

    &-item {
      flex: 1 1 auto;
      width: 50%;
      color: var(--colour-grey-mid-dark);
      font-size: var(--font-size-large);
      line-height: 1;
      text-transform: uppercase;
    }

    &-zd {
      color: var(--colour-brand);
      font-family: var(--font-headings);
    }
  }

  &-form {
    margin: var(--gutter-xlarge) 0 0;
  }
}
