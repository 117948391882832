.ToolHeader-notification {
  display: flex;
  align-items: center;
  margin-top: var(--content-padding-large);
  padding: var(--gutter) var(--gutter-large);
  background-color: var(--colour-grey-lighter);

  &.Icons-error::before {
    color: green;
    font-size: 200%;
  }

  p {
    margin: 0 0 0 8px;
  }
}

.ToolHeader-notification + .agent-portal-content {
  margin-top: 0;
}
