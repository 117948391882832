/**
 * @define FilterButton
 */

.FilterButton {
  position: relative;
  display: inline-block;
  color: var(--colour-grey-light) !important;
  font-size: var(--font-size-large);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: white !important;
  }

  &-arrow {
    color: var(--colour-main);
  }

  &:not(:first-child) {
    margin-left: var(--gutter);
  }
}
