/**
 * @define SelectAutocomplete
 */

.SelectAutocomplete {
  z-index: 8;
  overflow: initial;

  /* stylelint-disable plugin/selector-bem-pattern */

  .Input-field {
    z-index: 8;
  }

  /* stylelint-enable */
}

.SelectAutocomplete-list {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 100;
  display: block;
  font-size: var(--font-size-normal);
  background-color: var(--colour-body);
  box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.2);

  &--upwards {
    top: unset;
    bottom: 100%;
  }
}

.SelectAutocomplete-item {
  position: relative;
  z-index: 8;
  padding: var(--gutter-smaller) var(--gutter-small);
  cursor: pointer;

  &:hover,
  &.is-selected {
    background-color: var(--colour-grey-lighter);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--colour-grey-lighter);
  }
}

.SelectAutocomplete-select {
  display: none;
}
