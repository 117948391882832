/**
 * @define ToolHeader
 */

.ToolHeader {
  position: fixed;
  z-index: 100;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: var(--gutter) var(--gutter-large);
  color: white;
  background-color: var(--colour-brand);

  &-title {
    display: flex;
    align-items: center;
    color: white;
    font-size: 20px;
    font-family: var(--font-body);
    white-space: nowrap;
  }

  &-logo {
    display: block;
  }

  &-groupname {
    position: relative;
    display: inline-block;
    margin-left: var(--gutter-large);
    padding-left: var(--gutter-large);
    color: white;
    font-size: var(--font-size-large);

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px;
      background-color: var(--colour-grey-light);
      content: '';
    }
  }

  &-right-content {
    color: white;
    font-size: 14px;
    font-family: var(--font-body);

    &--large {
      font-size: 18px;
    }
  }

  &-right-item {
    position: relative;
    display: inline-block;
    color: white;
    white-space: nowrap;
    text-decoration: none;
    transition: color 0.2s ease;

    &:not(:first-child) {
      margin-left: var(--gutter);
    }

    &:hover {
      color: var(--colour-grey-lighter);
    }

    &:hover .ToolHeader-user-loz {
      color: white !important;
      background-color: var(--colour-main) !important;
    }

    &:hover .ToolHeader-right-icon {
      color: var(--colour-main);
    }
  }

  &-steps {
    display: inline-block;
    margin-right: var(--gutter-large);
    color: white;
    font-size: var(--font-size-large);
    white-space: nowrap;

    &-right {
      color: var(--colour-grey-mid-dark);
    }
  }

  &-right-icon {
    position: relative;
    display: inline-block;
    margin-left: var(--gutter-small);
    font-size: var(--font-size-large);
    transition: color 0.2s ease;

    &-label {
      position: absolute;
      right: -var(--gutter);
      bottom: -var(--gutter);
      left: -var(--gutter);
      padding-top: var(--gutter-small);
      color: var(--colour-grey-light);
      font-size: var(--font-size-tiny);
      text-align: center;
      text-transform: uppercase;
    }
  }

  &-user-loz {
    position: relative;
    display: inline-block;
    margin-left: var(--gutter-small);
    padding: 8px 10px 5px 10px;
    border-radius: 13px;
    color: var(--colour-brand) !important;
    font-size: 12px;
    text-decoration: none;
    background-color: white;
    transition: all 0.2s ease;
  }

  &-user-context-menu {
    top: 0;
    right: 0;
    left: unset;
    min-width: 200px;
  }

  &-status {
    margin-left: var(--gutter-large);
  }

  &-status-pill {
    margin-left: var(--gutter-large);
    padding: var(--gutter-smaller) var(--gutter-medium)
      calc(var(--gutter-smaller) - 2px);
    border-radius: var(--border-radius-pill);
    color: var(--colour-brand);
    font-size: var(--font-size-tiny);
    font-family: var(--font-headings);
    text-transform: uppercase;
    background-color: white;

    &--warning {
      color: white;
      background-color: var(--colour-main);
    }
  }
}
