/**
 * @define ContextMenu
 */

.ContextMenu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  min-width: 230px;
  border: var(--colour-grey-lighter) 1px solid;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  transition: opacity 0.2s ease;

  .DashboardTable-context-menu & {
    right: 0;
    left: unset;
  }

  &--hidden {
    opacity: 0;
  }

  &-item {
    position: relative;
    display: block;
    min-width: 230px;
    min-height: 55px;
    padding: 17px 15px 13px;
    color: var(--colour-brand) !important;
    font-size: var(--font-size-normal);
    white-space: nowrap;
    text-decoration: none;
    transition: all 0.2s ease;

    &:hover {
      color: black;
      background-color: var(--colour-grey-lighter);
    }

    &:not(:first-child) {
      border-top: var(--colour-grey-lighter) 1px solid;
    }
  }

  &-icon {
    position: absolute;
    top: 50%;
    right: var(--gutter-small);
    color: var(--colour-grey-mid);
    transform: translateY(-50%);
  }

  &--fixed-width &-item {
    min-width: 250px;
  }
}
