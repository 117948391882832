/**
 * @define DashboardTable
 */

.DashboardTable {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: var(--font-size-small);
  font-family: var(--font-body);

  &-row {
    display: table-row;
    border-bottom: 1px solid #eee;
    vertical-align: initial;
  }

  &-head {
    color: var(--colour-brand);
    font-weight: normal;
    font-family: var(--font-headings);
    text-transform: uppercase;
    vertical-align: bottom;
  }

  &-body {
    vertical-align: top;

    tr {
      backface-visibility: hidden;
      cursor: pointer;
      transition: background-color 0.2s ease;
    }

    tr:hover {
      background-color: var(--colour-grey-table-hover);
    }
  }

  &-col {
    position: relative;
    padding: var(--gutter);
  }

  &-col-width-id {
    width: 2%;
    min-width: 50px;
  }

  &-col-width-address {
    width: 45%;
  }

  &-col-width-date {
    min-width: 120px;
  }

  &-col-width-tenants {
    width: 35%;
  }

  &-col-width-status {
    width: 15%;
    min-width: 150px;
  }

  &-col-address {
    padding: 15px var(--gutter);
    font-size: var(--font-size-large);
  }

  &-tenant {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 var(--gutter-small);
    line-height: 1;
    vertical-align: middle;

    &:first-child {
      margin-top: 2px;
    }

    &-tick {
      color: var(--colour-main);
      transform: scale(1.2);
    }
  }

  &-context {
    @mixin buttonHitarea;
    position: absolute;
    top: var(--gutter);
    right: var(--gutter-small);
    z-index: 100;
    color: var(--colour-grey-light) !important;
    font-size: var(--font-size-large);
    text-decoration: none !important;
    opacity: 0;
    transition: color 0.2s ease;

    &:hover {
      color: var(--colour-main) !important;
    }
  }

  &-body tr:hover &-context {
    opacity: 1;
  }

  &-context-menu {
    top: 0;
    right: 0;
    left: unset;
    min-width: 200px;
  }
}
