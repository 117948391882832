/**
 * @define ToolFooter
 */

.ToolFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 50px;
  padding: var(--gutter-medium);
  border-top: var(--colour-grey-lighter) 1px solid;
  font-size: var(--font-size-small);
  text-align: right;
  background-color: white;

  a {
    font-family: var(--font-headings);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
