/**
 * @define TabGroup
 */

.TabGroup {
  width: 100%;
  font-size: 0;
}

.TabGroup-tabs {
  width: 100%;
  border-bottom: var(--colour-grey-light) 1px solid;
}

.TabGroup-tab {
  @mixin buttonInteraction;

  display: inline-block;
  min-width: 150px;
  margin-right: -1px;
  margin-bottom: -1px;
  padding: var(--button-padding);
  color: var(--colour-grey-mid-dark);
  font-size: initial;
  white-space: nowrap;
  text-align: center;

  &.is-current {
    padding: var(--button-padding) calc(var(--button-padding) - 1px);
    border: var(--colour-grey-light) 1px solid;
    border-bottom: white 1px solid;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    color: var(--colour-grey-dark);
  }

  &[class*='Icons-'] {
    &::before {
      margin-right: 8px;
      vertical-align: top;
    }

    &::after {
      margin-left: 8px;
      vertical-align: top;
    }
  }
}

.TabGroup-contents {
  width: 100%;
  border-right: var(--colour-grey-light) 1px solid;
  border-bottom: var(--colour-grey-light) 1px solid;
  border-left: var(--colour-grey-light) 1px solid;
}

.TabGroup-content {
  display: none;
  width: 100%;
  padding: var(--gutter);
  font-size: initial;

  &.is-current {
    display: block;
  }
}
