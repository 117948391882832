/**
 * @define PageNavigation
 */

.PageNavigation {
  width: 100%;
  margin-top: var(--gutter);
  text-align: center;
}

.PageNavigation-contents {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  color: white;

  &--span {
    width: 100%;
  }
}

.PageNavigation-dir {
  display: block;
  flex: 0 0 auto;
  padding: var(--gutter-small);
  color: var(--colour-grey-mid) !important;
  text-decoration: none;
  transition: color var(--transition-default);

  &:hover {
    color: var(--colour-grey-mid-dark);
  }

  &.is-disabled {
    color: var(--colour-grey-light);
    pointer-events: none;
  }
}

.PageNavigation-pages {
  display: flex;
  flex: 2 0 auto;
  align-self: stretch;
}

.PageNavigation-page {
  display: block;
  flex: 2 0 0;
  align-self: stretch;
  padding: var(--gutter-small) calc(var(--gutter-small) + 2px);
  color: var(--colour-grey-dark) !important;
  font-family: var(--font-headings);
  text-align: center;
  text-decoration: none;
  transition: background-color var(--transition-default);

  &.is-border-left {
    border-left: var(--colour-grey-mid) 1px dashed;
  }

  &.is-border-right {
    border-right: var(--colour-grey-mid) 1px dashed;
  }
}

.PageNavigation-page {
  &:hover {
    border-radius: var(--border-radius);
    color: white !important;
    background-color: var(--colour-grey-mid-dark);
  }

  &.is-current {
    border-radius: var(--border-radius);
    color: white !important;
    background-color: var(--colour-grey-mid);
    pointer-events: none;
  }
}
